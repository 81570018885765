<template>
  <div>
    <v-container fluid>
      <v-card color="grey lighten-5" elevation="0">
        <v-card-title>
          <h3 class="font-weight-black display-0 basil--text">
            REALIZAR MERMAS
          </h3>
        </v-card-title>
      </v-card><br>
      <v-card elevation="2">
        <v-row>
          <v-col
            sm="12"
            xs="12"
            md="3"
            class="pt-7 pb-0"
          >
            <v-text-field
              v-model="search"
              ref="buscarProducto"
              outlined
              dense
              label="Buscar Producto"
              class="ml-4 mr-4"
              @keyup="filterWastages(search)"
              :append-icon="svgPath"
            ></v-text-field>
          </v-col>
          <v-spacer></v-spacer>
          <v-col
            cols="12"
            sm="12"
            xs="12"
            md="3"
          >
            <v-select
              v-model="wastage_reason_id"
              :items="listReason"
              item-text="name"
              item-value="id"
              clearable
              outlined
              dense
              :error-messages="errors.get('wastage_reason_id')"
              @input="errors.clear('wastage_reason_id')"
              label="Motivo de merma"
              class="mt-7 ml-4 mr-4"
            ></v-select>
          </v-col>
        </v-row>
      </v-card><br>
      <TableProductWastages
        v-if="products.length"
        v-show="showTableProductWastages"
        :products="products"
        @wastages="setWastages($event)"
      />
      <TableWastage
        v-show="showTableWastages"
        :wastages="wastages"
        :successfully="successfully"
        @total="getTotal($event)"
        @cancel="cancelExecuteWastage"
        @save="saveExecuteWastage"
        @remove="removeList"
      />
    </v-container>
    <div class="text-center ma-2">
      <v-snackbar
        v-model="snackbar"
      >
        {{ text }}
        <template v-slot:action="{ attrs }">
          <v-btn
            color="pink"
            text
            v-bind="attrs"
            @click="snackbar = false"
          >
            Cerrar
          </v-btn>
        </template>
      </v-snackbar>
    </div>
    <div class="text-center ma-2">
      <v-snackbar
          v-model="snackbar2"
      >
        {{ text2 }}
        <template v-slot:action="{ attrs }">
          <v-btn
            color="pink"
            text
            v-bind="attrs"
            @click="snackbar2 = false"
          >
            Cerrar
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {mdiMagnify} from '@mdi/js';
import TableProductWastages from "../../components/tables/TableProductWastages";
import TableWastage from "../../components/tables/TableWastage";
import {mapState} from "vuex"
import {Errors} from "../../plugins/errors";

export default {
  name: "ExecuteWastage",
  components: {TableProductWastages, TableWastage},
  data () {
    return {
      svgPath: mdiMagnify,
      snackbar: false,
      snackbar2: false,
      successfully: false,
      search: '',
      wastage_reason_id: '',
      total: 0.0,
      listReason: [],
      wastages: [],
      products: [],
      errors: new Errors(),
      showTableProductWastages: false,
      showTableWastages: true,
      text: 'Operación realizada con éxito !!!',
      text2: 'No hay productos en la lista !!!',
      parentLaboratory: {
        id: null,
        name: ''
      },
      parentGeneric: {
        id: null,
        name: ''
      },
      parentCategory: {
        id: null,
        name: ''
      },
      parentPresentation: {
        id: null,
        name: ''
      },
      parentLocation: {
        id: null,
        name: ''
      },
    }
  },
  computed: {
    ...mapState('auth', ['user'])
  },
  mounted () {
    this.fetchReason()
  },
  methods: {
    filterWastages (search) {
      if (search.length >= 3) {
        let modifyList = []
        let quotient = 0
        let rest     = 0
        axios.get(`/api/wastage/products/${search}`)
          .then(response => {
            modifyList = response.data.data
            if (modifyList.length > 0) {
              /*modifyList.filter((value) => {
                return value.laboratory == null ? value.laboratory = this.parentLaboratory : value.laboratory
              })
              modifyList.filter((value) => {
                return value.generic == null ? value.generic = this.parentGeneric : value.generic
              })
              modifyList.filter((value) => {
                return value.category == null ? value.category = this.parentCategory : value.category
              })
              modifyList.filter((value) => {
                return value.presentation == null ? value.presentation = this.parentPresentation : value.presentation
              })
              modifyList.filter((value) => {
                return value.location == null ? value.location = this.parentLocation : value.location
              })*/

              modifyList.filter((value) => {
                if (value.product.stock !== null) {
                  if (value.product.box_quantity > 1) {
                    quotient = parseInt(value.stock_quantity) % parseInt(value.product.box_quantity)
                    rest  = (parseInt(value.stock_quantity) - parseInt(quotient)) / parseInt(value.product.box_quantity)
                    value.stock_quantity = rest + 'F' + quotient
                  }else {
                    value.stock_quantity = parseInt(value.stock_quantity)
                  }
                  return value.product.stock
                }
              })
              this.products = modifyList
              this.products.length ? this.showTableProductWastages = true : this.showTableProductWastages = false
              this.showTableWastages = false
            }else {
              this.showTableProductWastages = false
              this.showTableWastages = true
            }
          })
          .catch(error => console.log(error))
      }else {
        this.showTableProductWastages = false
        this.showTableWastages = true
      }
    },
    getTotal ($event) {
      this.total = $event
    },
    fetchReason () {
      axios.get('/api/wastage/reason')
          .then(response => this.listReason = response.data.data)
          .catch(error => console.log(error))
    },
    setWastages ($event) {
      this.wastages = $event
      this.showTableWastages = true
      this.showTableProductWastages  = false
      this.search = ''
    },
    cancelExecuteWastage () {
      this.reset()
    },
    reset () {
      this.listReason = []
      this.wastage_reason_id = ''
      this.wastages = []
      this.products = []
      this.total = 0.0
      this.fetchReason()
    },
    saveExecuteWastage () {
      let name_reason = ''
      if (this.wastage_reason_id !== '') {
          name_reason = this.listReason.filter(value => {
          if (value.id === this.wastage_reason_id) {
            return value.name
          }
        })
      }

      if (!this.wastages.length) {
        this.snackbar2 = true
        return
      }

      let createExecuteWastage = {
        'total'             : this.total,
        'wastage_reason_id' : this.wastage_reason_id,
        'wastages'          : this.wastages,
        'area_assignment_id': this.user[0].id,
        'voucher'           : name_reason ? name_reason[0].name : ''
      }

      axios.post('/api/wastage', createExecuteWastage)
        .then(() => {
          this.showTableWastages = true
          this.snackbar = true
          this.successfully=true
          this.reset()
        },error => this.errors.record(error.response.data.errors));
    },
    removeList ($event) {
      const index = this.wastages.findIndex(item => item.id === $event)
      this.wastages.splice(index, 1)
    },
  }
}
</script>

<style scoped>
  .basil {
    background-color: #FFFBE6 !important;
  }
  .basil--text {
    color: #356859 !important;
  }
</style>
