<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="products"
      class="elevation-2"
      hide-default-footer
      @click:row="selectRow"
      item-key="index"
      :footer-props="{ 'items-per-page-options': [100] }"
    >
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: "TableProductWastages",
  props: ['products'],
  data () {
    return {
      headers: [
        { text: 'NOMBRE',             value: 'product.name',                       sortable: false, width: "400px", class: "success white--text"},
        { text: 'FECHA',              value: 'expiration_date',                    sortable: false, width: "100px", class: "success white--text"},
        { text: 'STOCK',              value: 'stock_quantity',                     sortable: false, width: "50px", class: "success white--text"},
        { text: 'LABORATORIO',        value: 'product.laboratory.name',            sortable: false, width: "100px", class: "success white--text"},
        { text: 'UNIDAD MEDIDA',      value: 'product.presentation.name',          sortable: false, width: "50px",  class: "success white--text"},
        { text: 'PRECIO UNITARIO',    value: 'product.sale_unit',                  sortable: false, width: "50px",  class: "success white--text"},
        { text: 'PRECIO BLISTER',     value: 'product.sale_blister',               sortable: false, width: "50px",  class: "success white--text"},
        { text: 'PRECIO CAJA',        value: 'product.sale_box',                   sortable: false, width: "50px",  class: "success white--text"},
        { text: 'PRINCIPIO ACTIVO',   value: 'product.generic.name',               sortable: false, width: "100px", class: "success white--text"},
        { text: 'ACCIÓN TERAPEÚTICA', value: 'product.category.name',              sortable: false, width: "100px", class: "success white--text"},
      ],
      wastages: []
    }
  },

  watch: {
    products () {
      if (this.products.length > 0 && this.flag === true) {
        this.selectRow(this.products[0])
      }
    }
  },
  created () {
    if (this.products.length > 0 && this.flag === true) {
      this.selectRow(this.products[0])
    }
  },

  methods: {
    showProducts (item) {
      this.items = item
    },
    selectRow (row) {

          if (row.product.box_quantity> 1) {
            row.quantity = '0F1'
          }else {
            row.quantity = '1'
          }
          this.wastages.push(row)

        this.$emit('wastages', this.wastages)
    }
  }
}
</script>

<style scoped>

</style>