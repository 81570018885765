<template>
  <tr>
    <td>{{index + 1}}</td>
    <td v-if="item">
      <span>{{item.product.name}}</span>
    </td>
    <td v-if="item">
      <span>{{item.lot}}</span>
    </td>
    <td v-else>
      <span></span>
    </td>
    <td>
      <span>{{ item.expiration_date }}</span>
    </td>
    <td v-if="item.product.box_quantity > 1">
      <span v-if="!editable">{{ item.quantity }}</span>
      <v-text-field
        v-else
        v-model="item.quantity"
        outlined
        dense
        class="mt-6"
        v-uppercase
        solo-inverted
        @change="changeQuantity(item.quantity)"
        @input="up"
      ></v-text-field>
    </td>
    <td v-else>
      <span v-if="!editable">{{ item.quantity }}</span>
      <v-text-field
        v-else
        v-model="item.quantity"
        outlined
        dense
        class="mt-6"
        solo-inverted
        sm="6"
        @input="changeQuantity(item.quantity)"
        type="number"
        min="1"
        step="1"
      ></v-text-field>
    </td>
    <td v-if="item">
      <span>{{ item.product.buy_unit  }}</span>
    </td>
    <td v-if="item">
      <span>{{ item.product.buy_blister  }}</span>
    </td>
    <td v-if="item">
      <span>{{ item.product.buy_box }}</span>
    </td>
    <td v-if="item.total === totalPartial">
      <span>{{ totalPartial | currency }}</span>
    </td>
    <td v-else>
      <span>{{ item.total | currency }}</span>
    </td>
    <td>
      <v-icon
        v-if="editable"
        small
        class="mr-2"
        @click="toggleEditable()"
      >
        {{ icons.mdiCheckBold }}
      </v-icon>
      <v-icon
        v-if="editable"
        small
        class="mr-2"
        @click="removeItem()"
      >
        {{ icons.mdiDelete }}
      </v-icon>
      <template v-else>
        <v-icon
          small
          class="mr-2"
          @click="toggleEditable()"
        >
          {{ icons.mdiPencil }}
        </v-icon>
        <v-icon
          small
          class="mr-2"
          @click="removeItem()"
        >
          {{ icons.mdiDelete }}
        </v-icon>
      </template>
    </td>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="headline">¿Esta seguro de eliminar este registro?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="closeDelete">Cancel</v-btn>
          <v-btn color="success" text @click="deleteItemConfirm">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title class="headline">Stock insuficiente, stock actual: </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="closeDialog">Cancel</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </tr>
</template>

<script>
import {mdiAccount, mdiCheckBold, mdiDelete, mdiPencil, mdiShareVariant,} from '@mdi/js'
export default {
  name: "RowWastage",
  props: ['item', 'index'],
  data () {
    return {
      totalPartial: 0.0,
      editable: true,
      dialogDelete: false,
      dialog: false,
      icons: {
        mdiAccount,
        mdiPencil,
        mdiShareVariant,
        mdiDelete,
        mdiCheckBold
      },
    }
  },
  computed: {
    total: {
      get () {
        return this.totalPartial
      },
      set (value) {
        this.totalPartial = value
      }
    },
    quantity: {
      get () {
        return this.item.quantity
      },
      set (value) {
        this.item.quantity = value
      }
    },
    stock_quantity: {
      get () {
        return this.item.stock_quantity
      },
      set (value) {
        this.item.stock_quantity = value
      }
    }
  },
  watch: {
    total () {
      this.$emit('total')
    },
    quantity () {
      this.changeQuantity(this.item.quantity)
    }
  },
  created (){
    this.item.lot = ''
    this.item.total = 0.0
    this.item.box = 0.0
    this.item.blister = 0.0
    this.item.unit = 0.0
    this.item.product_quantity = 0.0
    this.item.bunit=this.item.sale_unit
    this.changeQuantity(this.item.quantity)
  },
  methods: {
    toggleEditable() {
      this.editable = !this.editable
    },
    closeDelete () {
      this.dialogDelete = false
    },
    closeDialog () {
      this.dialog = false
      this.quantity=''
    },
    deleteItemConfirm () {
      this.remove()
      this.closeDelete()
    },
    removeItem () {
      this.dialogDelete = true
    },


    changeQuantity1 (val) {
      if (val.toString().includes("F")) {
        if (this.item.blister_quantity > 1) {
          let valFirst = 0
          let valLast = 0
          const values = val.split("F")
          for (let i = 0; i < values.length; i++) {
            valFirst = values[0]
            valLast = values[1]
          }
          if (valLast) {
            this.item.product_quantity = (parseFloat(valFirst) * parseFloat(this.item.box_quantity) + parseFloat(valLast)).toFixed(2)
            this.item.total            = (parseFloat(this.item.product_quantity) * parseFloat(this.item.buy_unit)).toFixed(2)
            this.totalPartial          = this.item.total
          }
        }
      }else{

        this.item.total   = (parseFloat(this.item.buy_unit) * parseFloat(val)).toFixed(2)
        this.totalPartial = this.item.total
      }
      this.$emit('dataRow', this.item)
    },

     changeQuantity (val) {
      let valFirst  = 0
      let valLast   = 0
      let valFirst1 = 0
      let valLast1  = 0
      let valFirst2 = 0
      let valLast2  = 0
      let quotient  = 0
      let rest      = 0
      let productStockt = this.item.stock_quantity
      let quantity      = this.item.quantity

      if (val.toString().includes("F")) {
        const valuesproductStockt = productStockt.split("F")
        for (let i = 0; i < valuesproductStockt.length; i++) {
          valFirst1 = valuesproductStockt[0]
          valLast1  = valuesproductStockt[1]
        }

        const valuesquantity = quantity.toString().split("F")
        for (let i = 0; i < valuesquantity.length; i++) {
          valFirst2 = valuesquantity[0]
          valLast2  = valuesquantity[1]
        }

        if (parseInt(valLast2) >= this.item.product.box_quantity) {
          quantity = (parseInt(valFirst2)+1)+'f'+ (parseInt(valLast2)-parseInt(this.item.product.box_quantity))
        }
        const values = val.toString().split("F")
        for (let i = 0; i < values.length; i++) {
          valFirst = values[0]
          valLast = values[1]
        }

        quantity = (parseInt(valFirst2) * parseFloat(this.item.product.box_quantity))+parseInt(valLast2)

        if (quantity) {
          productStockt =this.item.stock_quantity
          productStockt = parseInt(valFirst1) * parseFloat(this.item.product.box_quantity)+parseInt(valLast1)
          if(productStockt >= quantity) {
            this.item.box = (parseInt(valFirst) * parseFloat(this.item.product.buy_box)).toFixed(2)
            if(parseInt(this.item.product.blister_quantity) === parseInt(this.item.product.presentation_sale)) {
              this.item.unit = (parseInt(valLast) * parseFloat(this.item.product.buy_unit)).toFixed(2)
            }else if (parseInt(valLast) >= parseInt(this.item.product.presentation_sale)) {
              quotient = parseInt(valLast) % parseInt(this.item.product.blister_quantity)
              rest  = (parseInt(valLast) - parseInt(quotient)) / parseInt(this.item.product.blister_quantity)
              this.item.blister = (parseInt(rest) * parseFloat(this.item.product.buy_blister)).toFixed(2)
              this.item.unit    = (parseInt(quotient) * parseFloat(this.item.product.buy_unit)).toFixed(2)
            }else {
              this.item.blister = (parseFloat(0) * parseFloat(this.item.product.buy_blister)).toFixed(2)
              this.item.unit    = (parseInt(valLast) * parseFloat(this.item.product.buy_unit)).toFixed(2)
            }
            this.item.total = parseFloat(this.item.box ) + parseFloat(this.item.blister) + parseFloat(this.item.unit )
            this.totalPartial = this.item.total
          }else {
            this.dialog=true
          }
        }
      }else {
        if(productStockt >= quantity){
          this.item.box     = parseFloat(0).toFixed(2)
          this.item.blister = parseFloat(0).toFixed(2)
          this.item.unit    = (parseInt(val) * parseFloat(this.item.product.buy_unit)).toFixed(2)
          this.item.total   = parseFloat(this.item.box ) + parseFloat(this.item.blister) + parseFloat(this.item.unit )
          this.totalPartial = this.item.total
        }else {
          this.dialog = true
        }
      }
      this.$emit('dataRow', this.item)
    },

    up () {
      if (parseInt(this.item.quantity) > -1 && !this.item.quantity.includes('F')) {
        this.$nextTick(() => {
          this.item.quantity =  parseInt(this.item.quantity) + 'F0'
        })
      }
      if (this.item.quantity.includes('F')) {
        let valFirst = 0
        let valLast  = 0
        let resto = 0
        let cocient = 0

        const value=this.item.quantity.split("F")
        for (let i = 0; i < this.item.quantity.length; i++) {
          valFirst = value[0]
          valLast  = value[1]
        }

        if (valFirst>0) {
          if(valLast>=this.item.product.box_quantity) {
            resto = parseInt(valLast)%this.item.product.box_quantity
            cocient = parseInt(Math.floor(valLast / this.item.product.box_quantity )) + parseInt(valFirst)
            this.item.quantity =  cocient +'F'+ resto
          }
        }else {
          if(valLast>=this.item.product.box_quantity) {
            resto = parseInt(valLast)%this.item.product.box_quantity
            valFirst = Math.floor(valLast / this.item.product.box_quantity )
            this.item.quantity =  valFirst +'F'+ resto
          }
        }
      }
    },
    remove () {
      this.$emit('removeList', this.item.id)
    },
  }
}
</script>

<style scoped>

</style>
