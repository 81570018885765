<template>
  <div>
    <v-simple-table  class="elevation-2">
      <template v-slot:default>
        <thead>
        <tr style="background: #4caf50">
          <th class="text-left d-block d-sm-table-cell" style="color: white">
            NRO
          </th>
          <th class="text-left" style="color: white; width: 400px">
            PRODUCTO
          </th>
          <th class="text-left" style="color: white">
            LOTE
          </th>
          <th class="text-left" style="color: white">
            VENCIMIENTO
          </th>
          <th class="text-left" style="color: white">
            CANTIDAD
          </th>
          <th class="text-left" style="color: white">
            PC. UNIDAD
          </th>
          <th class="text-left" style="color: white">
            PC. BLISTER
          </th>
          <th class="text-left" style="color: white">
            PC. CAJA
          </th>
          <th class="text-left" style="color: white">
            TOTAL
          </th>
          <th class="text-left" style="color: white">
            ACCIONES
          </th>
        </tr>
        </thead>
        <tbody>
          <RowWastage
            @total="dataTotal"
            @removeList="removeList($event)"
            @dataRow="dataRow($event)"
            :item="item"
            :index="index"
            v-for="(item, index) in wastages"
            :key="index"
          />
        </tbody>
      </template>
    </v-simple-table><br>
    <v-footer color="grey lighten-5">
      <v-row>
        <v-col md="10" class="text-right justify-center">
          <h4 class="font-weight-black display-1 basil--text">
            TOTAL
          </h4>
        </v-col>
        <v-col md="2" class="text-right justify-center">
          <h4 class="font-weight-black display-1 basil--text">
            {{ total | currency}}
          </h4>
        </v-col>
      </v-row>
    </v-footer>
    <div class="my-4 float-md-right">
      <v-btn
        large
        color="green darken-4"
        dark
        @click="$emit('save')"
      >
        <v-icon left>
          mdi-content-save
        </v-icon>
        REALIZAR MERMA
      </v-btn>
    </div>
    <div class="my-4 float-md-right mr-5">
      <v-btn
        large
        color="lime darken-4"
        dark
        @click.stop="$emit('cancel'); reset()"
      >
        <v-icon left>
          mdi-cancel
        </v-icon>
        CANCELAR
      </v-btn>
    </div>
  </div>
</template>

<script>
import RowWastage from "../rows/RowWastage";
export default {
  name: "TableWastage",
  components: {RowWastage},
  props: ['wastages', 'successfully'],
  data () {
    return {
      total: 0.0,
      dataWastage: [],
      totalRow: [],
    }
  },
  watch: {
    successfully () {
      this.reset()
    }
  },
  methods: {
    dataTotal () {
        this.total = 0.0
        this.wastages.map(t => {
          this.total += parseFloat(t.total)
        })

        this.$emit('total', this.total);
    },
    dataRow ($event) {
      const indexItem = this.dataWastage.findIndex(item=> item.id === $event.id)
      if(indexItem >= 0){
        return ''
      }else{
        this.dataWastage.push($event)
      }
    },
    reset () {
      this.total = 0.0
      //this.dataWastage = []
    },
    removeList ($event) {
      this.$emit('remove', $event)
      const index = this.dataWastage.findIndex(item => item.id === $event)
      this.dataWastage.splice(index, 1)
      this.dataTotal()
    },
  }
}
</script>

<style scoped>

</style>
